import { Component, HostListener } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // title = 'Proto-GIS';
  temp: string;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.setCookiesBannerPosition();
  }

  constructor(
    public translate: TranslateService,
    private ccService: NgcCookieConsentService,
    public meta: Meta,
    public title: Title,
    private route: ActivatedRoute
  ) {
    translate.addLangs(['en', 'pl', 'de']);

    this.route.queryParams.subscribe(params => {
      const urlLang = params['lang'];
      let temp = localStorage.getItem('lang');

      if (urlLang && translate.getLangs().includes(urlLang)) {
        this.setLanguage(urlLang);
      } else if (!temp) {
        const browserLang = translate.getBrowserLang();
        if (browserLang && translate.getLangs().includes(browserLang)) {
          this.setLanguage(browserLang);
        } else {
          this.setLanguage('en');
        }
      } else {
        this.setLanguage(temp);
      }
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateMetaTags();
      this.updateSelectedLanguage();
    });
  }

  ngOnInit() {
    this.translate//
      .get(['cookie.message', 'cookie.allow', 'cookie.link', 'cookie.deny'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.message = data['cookie.message'];
        this.ccService.getConfig().content.allow = data['cookie.allow'];
        this.ccService.getConfig().content.link = data['cookie.link'];
        this.ccService.getConfig().content.deny = data['cookie.deny'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  setLanguage(lang: string) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    this.updateMetaTags();
    this.updateSelectedLanguage();
  }

  updateMetaTags() {
    let desc = this.translate.instant('seo.DESCRIPTION');
    this.meta.updateTag({ name: 'description', content: desc });
    this.title.setTitle(this.translate.instant('seo.TITLE'));
  }

  updateSelectedLanguage() {
    const currentLang = localStorage.getItem('lang')?.toUpperCase();
    $('#selected').text(currentLang);
  }

  setCookiesBannerPosition() {
    let cookiesBanner = document.querySelector('.cc-banner') as HTMLElement;
    if (cookiesBanner) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        cookiesBanner.style.marginBottom = '65px';
      } else {
        cookiesBanner.style.marginBottom = '0px';
      }
    }
  }

}
