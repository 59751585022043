import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

import { MenuComponent } from './menu/menu.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { ContactDialogComponent as ContactDialogComponent } from './core/dialogs/contact-dialog/contact-dialog.component';
import { WebpackTranslateLoader } from 'src/webpack-translate-loader';
import { ScrollToTopModule } from './scroll-to-top/scroll-to-top.model';
import { InvalidFormDialogComponent } from './core/dialogs/invalid-form-dialog/invalid-form-dialog.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#11313b'
    },
    button: {
      background: '#7dc9cd'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,    
    ContactDialogComponent,
    InvalidFormDialogComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ScrollToTopModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [    
    ContactDialogComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
